.about-sumban {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }
  .containerlogo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .logosumban {
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 100%;
      }
      .gambarsumban {
        width: 20%;
        margin-top: -50px;
        @media (max-width: 768px) {
          width: 50%;
          
        }
      }
    }
  }
  .containerdesk {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 95%;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-content: center;
    }
    .judul1 {
      width: 100%;
      display: flex;
      text-align: center;
      justify-content: center;
      @media (max-width: 768px) {
        width: 100%;
        //padding: 10px 50px;
        display: flex;
      flex-direction: column;
      justify-content: center;
      }

      h1 {
        width: 100%;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      margin-top: -10px;
      font-family: "poppins" sans-serif;
      font-size: 35px;
      font-weight: 600;
      color: #e1c06c;
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
        display: flex;
        font-size: 16px;
      flex-direction: column;
      justify-content: center;
      }
      }
      
    }
    .desk {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      color: rgb(85, 85, 85);
      font-family: "poppins" sans-serif;
      font-size: 15px;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
}